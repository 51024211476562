<script setup lang="ts">
import { useDevice } from "~/composables/useDevice"

defineEmits<{
  (e: "toggleDrawer"): void
}>()

const { isMobile } = useDevice()
</script>

<template>
  <v-app-bar app :density="isMobile ? 'compact' : 'default'">
    <v-container class="py-0">
      <v-row class="d-flex align-center">
        <v-col
          :class="`d-flex ${isMobile ? 'justify-space-between' : 'justify-start'}`"
          sm="4"
          cols="6"
          md="3"
        >
          <v-app-bar-nav-icon
            :class="isMobile ? 'justify-start' : 'justify-center'"
            @click="$emit('toggleDrawer')"
          />
          <NuxtLink :to="{ name: 'index' }" class="align-self-center">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
            </span>
          </NuxtLink>
        </v-col>
        <v-col sm="8" md="6" cols="6" class="d-flex justify-end">
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

