<script lang="ts" setup>
import { mdiArrowLeft, mdiMagnify } from "@mdi/js"
import { ref } from "vue"
import { useIntl } from "~/composables/intl"
import { useDevice } from "~/composables/useDevice"
import type { TickerSearchItem } from "#resources/Tickers"
import Flag from "~/components/Flag.vue"

const { trans } = useIntl()

const emit = defineEmits<{
  (e: "search", query: string): void
  (e: "goToSecurity", symbol: string): void
}>()

const { isSmall } = useDevice()

const isDialogOpen = ref(false)

const isActive = ref<boolean>(false)

defineProps<{
  isLoading: boolean
  securities: readonly TickerSearchItem[]
}>()

const query = defineModel<string>("query", { required: true })
</script>

<template>
  <v-autocomplete
    v-if="isSmall"
    v-model:search="query"
    v-model:menu="isActive"
    :active="isActive"
    clearable
    color="primary"
    :items="
      securities.map(el => ({
        title: el.name,
        value: el.symbol,
        ...el,
      }))
    "
    :clear-on-select="true"
    no-filter
    :label="trans('body.searchSecurity')"
    hide-details
    aria-autocomplete="none"
    class="search-security rounded-xl"
    :loading="isLoading"
    density="compact"
    :prepend-inner-icon="mdiMagnify"
  >
    <template #item="{ props, item }">
      <v-list-item
        v-if="securities.length"
        v-bind="props"
        :title="item.title"
        @click="() => emit('goToSecurity', item.value)"
      >
        <template #title="{ title }">
          {{ title }}
        </template>
        <template #subtitle>
          <b>{{ item.raw.symbol }}</b>
          {{ item.raw.stockExchange.name }}
          <Flag :country-code="item.raw.stockExchange.countryCode" />
        </template>
      </v-list-item>
    </template>
    <template #no-data>
      <v-list-item
        class="text-grey"
        :title="trans('text.no_signals_or_security')"
      ></v-list-item>
    </template>
  </v-autocomplete>

  <v-dialog v-else v-model="isDialogOpen" :fullscreen="true">
    <template #activator>
      <v-btn
        :icon="mdiMagnify"
        variant="flat"
        @click="isDialogOpen = true"
      ></v-btn>
    </template>

    <template #default>
      <v-card>
        <v-card-title class="d-flex">
          <v-spacer />
          <v-btn
            flat
            class="rounded-lg px-0"
            size="small"
            @click="isDialogOpen = false"
          >
            <template #prepend>
              <v-icon :icon="mdiArrowLeft"></v-icon>
            </template>
            {{ trans("body.back") }}
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-autocomplete
            v-model:search="query"
            color="primary"
            :items="
              securities.map(el => ({
                title: el.name,
                value: el.symbol,
                ...el,
              }))
            "
            no-filter
            :label="trans('body.searchSecurity')"
            hide-details
            aria-autocomplete="none"
            class="search-security rounded-xl"
            :loading="isLoading"
            density="compact"
            :prepend-inner-icon="mdiMagnify"
          >
            <template #item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item.title"
                @click="
                  () => {
                    emit('goToSecurity', item.value)
                    isDialogOpen = false
                  }
                "
              >
                <template #title="{ title }">
                  {{ title }}
                </template>
                <template #subtitle>
                  <b>{{ item.raw.symbol }}</b>
                  {{ item.raw.stockExchange.name }}
                  <Flag :country-code="item.raw.stockExchange.countryCode" />
                </template>
              </v-list-item>
            </template>
            <template #no-data>
              <v-list-item
                :title="trans('text.no_signals_or_security')"
              ></v-list-item>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
